import React from 'react'
import { connect } from 'react-redux'
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto'
import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/MyDocumentListCss'
import {
    updateStateEvent,
    getImageToDisplay,
    putBOBEvents,
    postBOBEvents,
    addFileToBobEvent,
    deleteFileFromBobEvent,
} from '../../../../actions/bob.actions'
import { trainingActions } from '../../../../actions/bob/trainings.actions'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import BoatOnModalCore from '../../../common/BoatOnModalCore'
import DisplayerImgModal from '../../DisplayerImgModal'
import InputAdornment from '@material-ui/core/InputAdornment'
import { typesActions } from '../../../../actions/types.actions'
import RemoveIcon from '@material-ui/icons/Remove'
import AddIcon from '@material-ui/icons/Add'
import dictionary from './Dictionary/MyDocumentListDico'
import { Tooltip } from '@material-ui/core'
import HelpIcon from '@material-ui/core/SvgIcon/SvgIcon'
import BoatOnModal from '../../../common/BoatOnModal'
import ModalAmountItCheck from '../../../common/UsefullComponents/ModalAmountItCheck'
import BoatOnDateSelector from '../../../common/UsefullComponents/BoatOnDateSelector'
import { activityActions } from '../../../../actions/activity.actions'
import { commentsActions } from '../../../../actions/bob/comments.actions'
import BoatOnNumberField from '../../../common/UsefullComponents/BoatOnNumberField'
import UserSelector from '../../../common/UserSelector/UserSelector'
import BoatOnAutoComplete from '../../../common/BoatOnAutoComplete'

class DocumentModal extends BoatOnModalCore {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            event: props.event || null,
            training: props.training || null,
            fileNoUrl: {
                event: [],
                training: [],
            },
            displayNewFiles: {
                event: [],
                training: [],
            },
            openDoc: false,
            deletedFiles: {
                event: [],
                training: [],
            },
            checkModalOpen: null,
            error: false,
            tabIndex: 0,
            loadingImage: false,
            tax: 20,
            newTraining: null,
        }

        this.trainingInputRef = React.createRef()

        this.save = this.save.bind(this)
        this.clickDocument = this.clickDocument.bind(this)
        this.addDocument = this.addDocument.bind(this)
        this.deleteDocumentNoUp = this.deleteDocumentNoUp.bind(this)
        this.isConfirmedToggler = this.isConfirmedToggler.bind(this)
        this.handleCloseCheckModal = this.handleCloseCheckModal.bind(this)
        this.onChangeTraining = this.onChangeTraining.bind(this)
        this.addNewTraining = this.addNewTraining.bind(this)
    }

    componentDidMount() {
        // Lancement du chargement de l'activité lorsque la modale est montée
        if (this.state.event?.id) {
            this.props.dispatch(
                activityActions.getBobEventActivity(this.state?.event?.id),
            )
            this.props.dispatch(
                commentsActions.getBobEventComments(this.state?.event?.id),
            )
        }

        if (this.state.event?.transaction) {
            const transaction = this.state.event.transaction

            if (transaction?.amountIt && transaction?.amountEt) {
                const amountEt = parseFloat(transaction.amountEt)
                const amountIt = parseFloat(transaction.amountIt)
                const tax = (amountIt / amountEt - 1) * 100
                const roundedTax = Math.round(tax * 100) / 100

                this.setState({ tax: roundedTax })
            }
        }

        this.props.dispatch(
            typesActions.requestTrainingTypes(this.props.groupId),
        )

        if (this.props.forceEventType) {
            this.setState({
                event: {
                    eventType: this.props.forceEventType,
                },
            })
        }

        if (this.props.initialUser) {
            this.setState({
                training: {
                    ...(this.state.training || {}),
                    user: this.props.initialUser,
                },
            })
        }
    }

    clickDocument() {
        this.refs.fileUploader.click()
    }

    // CheckModal
    isConfirmedToggler() {
        this.setState({ isConfirmed: true })
    }

    handleCloseCheckModal() {
        this.setState({ checkModalOpen: null })
    }

    deleteDocumentNoUp(i) {
        const {
            event,
            training,
            deletedFiles,
            displayNewFiles,
            fileNoUrl,
        } = this.state

        if (event?.eventType?.id !== 22) {
            deleteFileFromBobEvent(this, i, {
                deletedFiles: deletedFiles.event,
                displayNewFiles: displayNewFiles.event,
                fileNoUrl: fileNoUrl.event,
                files: event.files,
            })
        } else {
            deleteFileFromBobEvent(this, i, {
                deletedFiles: deletedFiles.training,
                displayNewFiles: displayNewFiles.training,
                fileNoUrl: fileNoUrl.training,
                files: training.files,
                name: 'training',
            })
        }
    }

    addDocument(event, name) {
        if (
            event?.target?.files &&
            [...event.target.files].find(file =>
                file.name.toLowerCase().includes('.heic'),
            )
        ) {
            // So we display a loading icon during the time of heic conversion
            this.setState({ loadingImage: true }, async () => {
                await addFileToBobEvent(this, event, {
                    fileNoUrl: this.state.fileNoUrl[name],
                    displayNewFiles: this.state.displayNewFiles[name],
                    name: name,
                })
                this.setState({ loadingImage: false })
            })
        } else {
            addFileToBobEvent(this, event, {
                fileNoUrl: this.state.fileNoUrl[name],
                displayNewFiles: this.state.displayNewFiles[name],
                name: name,
            })
        }
    }

    onChangeAmount(type, value) {
        const { event, tax } = this.state

        value = value.toString().replace(',', '.')
        const transaction =
            type === 'amountIt'
                ? {
                      amountIt: value,
                      amountEt: parseFloat(value / (1 + tax / 100)).toFixed(2),
                  }
                : {
                      amountIt: parseFloat(value * (1 + tax / 100)).toFixed(2),
                      amountEt: value,
                  }

        if (this.state.event?.eventType?.id === 22) {
            this.onChangeTraining('transaction', transaction)
        } else {
            updateStateEvent(this, {
                transaction,
                negativeTransaction: event?.eventType?.id === 1,
            })
        }
    }

    onChangeTax(value) {
        const { event, training } = this.state

        this.setState(
            {
                tax: value,
            },
            () =>
                this.onChangeAmount(
                    'amountEt',
                    event?.transaction?.amountEt
                        ? event.transaction.amountEt
                        : training?.transaction?.amountEt
                        ? training.transaction.amountEt
                        : '',
                ),
        )
    }

    onChangeTraining(property, value) {
        if (property === 'trainingType') {
            if (value?.fromBoatOn) property = 'trainingType'
            else property = 'userTraining'
        }
        if(property === 'userTraining' || property === 'trainingType'){
            this.setState({
                training: {
                    ...this.state.training,
                    userTraining: null,
                    trainingType: null,
                    [property]: value,
                },
            })
        }else{
            this.setState({
                training: {
                    ...this.state.training,
                    [property]: value,
                },
            })
        }



    }

    _renderAmount() {
        const { classes, eventTypes } = this.props
        const { event, tax, training } = this.state

        const eventType = typesActions.getEventTypeById(
            eventTypes,
            this.state.event && this.state.event.eventType.id,
        )

        return (
            <div className={classes.amountContainer}>
                <BoatOnNumberField
                    isDecimal
                    id="amountEt"
                    variant="outlined"
                    label={
                        <>
                            {this.displayText('amountEt')}
                            {this.props.subscriptions ? (
                                <Tooltip
                                    title={this.displayText(
                                        'subscribesForAmount',
                                    )}
                                    classes={{
                                        tooltip: classes.tooltip,
                                    }}
                                >
                                    <HelpIcon className={classes.helpIcon} />
                                </Tooltip>
                            ) : null}
                        </>
                    }
                    margin="dense"
                    value={
                        event?.transaction?.amountEt
                            ? event.transaction.amountEt
                            : training?.transaction?.amountEt
                            ? training.transaction.amountEt
                            : ''
                    }
                    onChange={e =>
                        this.onChangeAmount('amountEt', e.target.value)
                    }
                    className={classes.amountField}
                    InputProps={{
                        classes: {
                            input: classes.input,
                            marginDense: classes.marginInput,
                        },
                        startAdornment: (
                            <InputAdornment position="start">
                                {eventType?.spending !== eventType?.earning ? (
                                    eventType.spending ? (
                                        <RemoveIcon fontSize="small" />
                                    ) : (
                                        <AddIcon fontSize="small" />
                                    )
                                ) : null}
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">€</InputAdornment>
                        ),
                    }}
                    InputLabelProps={{
                        classes: { root: classes.labelInput },
                    }}
                />
                <BoatOnNumberField
                    isDecimal
                    variant="outlined"
                    label={this.displayText('tax')}
                    margin="dense"
                    value={tax}
                    min={0}
                    max={100}
                    onChange={e => this.onChangeTax(e.target.value)}
                    className={classes.taxField}
                    InputProps={{
                        classes: {
                            input: classes.input,
                            marginDense: classes.marginInput,
                        },
                        endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                        ),
                    }}
                    InputLabelProps={{
                        classes: { root: classes.labelInput },
                    }}
                />
                <BoatOnNumberField
                    isDecimal
                    id="amountIt"
                    variant="outlined"
                    label={
                        <>
                            {this.displayText('amountIt')}
                            {this.props.subscriptions ? (
                                <Tooltip
                                    title={this.displayText(
                                        'subscribesForAmount',
                                    )}
                                    classes={{
                                        tooltip: classes.tooltip,
                                    }}
                                >
                                    <HelpIcon className={classes.helpIcon} />
                                </Tooltip>
                            ) : null}
                        </>
                    }
                    margin="dense"
                    value={
                        event?.transaction?.amountIt
                            ? event.transaction.amountIt
                            : training?.transaction?.amountIt
                            ? training.transaction.amountIt
                            : ''
                    }
                    onChange={e =>
                        this.onChangeAmount('amountIt', e.target.value)
                    }
                    className={classes.amountField}
                    InputProps={{
                        classes: {
                            input: classes.input,
                            marginDense: classes.marginInput,
                        },
                        startAdornment: (
                            <InputAdornment position="start">
                                {eventType?.spending !== eventType?.earning ? (
                                    eventType.spending ? (
                                        <RemoveIcon fontSize="small" />
                                    ) : (
                                        <AddIcon fontSize="small" />
                                    )
                                ) : null}
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">€</InputAdornment>
                        ),
                    }}
                    InputLabelProps={{
                        classes: {
                            root: classes.labelInput,
                        },
                    }}
                />
            </div>
        )
    }

    addNewTraining() {
        const value = this.trainingInputRef.current.getElementsByTagName(
            'input',
        )[0].value
        console.log("clic")
        this.setState({
            newTraining: {
                id: 0,
                name: value,
                fromBoatOn: false,
                createdBy: this.props.currentUser,
            },
            training: {
                ...this.state.training,
                userTraining: {
                    id: 0,
                    name: value,
                    fromBoatOn: false,
                    createdBy: this.props.currentUser,
                },
                trainingType: null,
            },
        })
    }

    _body() {
        const {
            classes,
            forceEventType,
            eventTypes,
            trainingTypes,
        } = this.props
        const { event, error, training, newTraining } = this.state

        const eventType = typesActions.getEventTypeById(
            eventTypes,
            this.state.event && this.state.event.eventType.id,
        )

        return (
            <>
                <TextField
                    id="type"
                    variant="outlined"
                    label={this.displayText('choiceType')}
                    margin="dense"
                    required
                    select
                    error={!event?.eventType?.id && error}
                    helperText={
                        !event?.eventType?.id && error
                            ? this.displayText('errorChoiceType')
                            : ''
                    }
                    value={event?.eventType?.id ? event.eventType.id : -1}
                    onChange={e => {
                        updateStateEvent(this, { eventTypeId: e.target.value })
                    }}
                    className={classes.textField}
                    InputProps={{
                        classes: {
                            input: classes.input,
                            marginDense: classes.marginInput,
                        },
                    }}
                    InputLabelProps={{
                        classes: {
                            root: classes.labelInput,
                        },
                    }}
                    disabled={forceEventType}
                >
                    {!event?.eventType?.id && (
                        <MenuItem disabled value={-1}>
                            <em> {this.displayText(`typeEv`)} </em>
                        </MenuItem>
                    )}
                    {eventTypes.map(type => {
                        return (
                            <MenuItem key={type.id} value={type.id}>
                                {this.displayTextApi(type.translation)}
                            </MenuItem>
                        )
                    })}
                </TextField>
                {event?.eventType?.id === 22 && (
                    <>
                        <UserSelector
                            required
                            classes={{ input: classes.userInput }}
                            onChange={users =>
                                this.onChangeTraining(
                                    'user',
                                    users.length > 0
                                        ? users[users.length - 1]
                                        : null,
                                )
                            }
                            label={this.displayText('user')}
                            errorMessageMandatory={this.displayText(
                                'errorUser',
                            )}
                            value={training?.user ? [training.user] : []}
                            error={error && !training?.user}
                        />
                        <BoatOnAutoComplete
                            required
                            autoComplete
                            renderTagCondition={option => option.id}
                            getOptionSelected={(opt, value) =>
                                opt?.id === value?.id &&
                                opt?.fromBoatOn === value?.fromBoatOn
                            }
                            value={
                                training?.trainingType
                                    ? training.trainingType
                                    : training?.userTraining
                                    ? training.userTraining
                                    : null
                            }
                            getElementToShow={opt => {
                                if (opt?.button) return ''

                                return opt.fromBoatOn
                                    ? this.displayTextApi(opt.translation)
                                    : opt.name
                            }}
                            options={[...(trainingTypes || []), newTraining]}
                            label={this.displayText('trainingName')}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            error={error && (!training?.trainingType || !training.userTraining)}
                            helperText={
                                error &&
                                (!training?.trainingType || !training.userTraining) &&
                                this.displayText('errorTrainingName')
                            }
                            onChange={(_, value) => {
                                const currentTraining = trainingTypes.find(
                                    train => train.id === value?.id,
                                )

                                if (currentTraining) {
                                    this.onChangeTraining(
                                        'trainingType',
                                        currentTraining,
                                    )
                                } else
                                    this.onChangeTraining('trainingType', value)
                            }}
                            getName={opt =>
                                opt.fromBoatOn
                                    ? this.displayTextApi(opt.translation)
                                    : opt.name
                            }
                            addButton
                            labelAdd={this.displayText('addTraining')}
                            onAddButtonClicked={this.addNewTraining}
                            filterOptions={(opt, search) => {
                                if (opt === null) return false

                                const value = opt.fromBoatOn
                                    ? this.displayTextApi(opt.translation)
                                    : opt.name

                                return value
                                    .toLowerCase()
                                    .includes(search.inputValue.toLowerCase())
                            }}
                            textFieldRef={this.trainingInputRef}
                        />
                    </>
                )}
                {(!event?.id ||
                    (event?.id && !event?.detail && !event?.checkup)) &&
                    event?.eventType?.id !== 22 && (
                        <TextField
                            id="title"
                            variant="outlined"
                            label={this.displayText('title')}
                            margin="dense"
                            required
                            error={!event?.title && error}
                            helperText={
                                !event?.title && error
                                    ? this.displayText('errorTitle')
                                    : ''
                            }
                            value={event?.title ? event.title : ''}
                            onChange={e => {
                                updateStateEvent(this, {
                                    title: e.target.value,
                                })
                            }}
                            className={classes.textField}
                            InputProps={{
                                classes: {
                                    input: classes.input,
                                    marginDense: classes.marginInput,
                                },
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.labelInput,
                                },
                            }}
                        />
                    )}
                <Typography variant={`body1`} style={{ margin: `10px 0px` }}>
                    {this.displayText('ifNeeded')}
                </Typography>
                <div
                    className={`${classes.twoTextField} ${classes.verticalMobile}`}
                >
                    <BoatOnDateSelector
                        required
                        disabled={
                            event?.delimitedDate === null &&
                            training?.delimitedDate === null
                        }
                        error={
                            error &&
                            !event?.delimitedDate?.startDate &&
                            !training?.delimitedDate?.startDate
                        }
                        helperText={
                            error &&
                            (!event?.delimitedDate?.startDate ||
                                !training?.delimitedDate?.startDate)
                                ? this.displayText('errorDate')
                                : ''
                        }
                        label={this.displayText('date')}
                        onChange={value => {
                            if (event?.eventType?.id === 22)
                                this.onChangeTraining('delimitedDate', {
                                    ...training?.delimitedDate,
                                    startDate: value,
                                })
                            else
                                updateStateEvent(this, {
                                    delimitedDate: {
                                        startDate: value,
                                    },
                                })
                        }}
                        value={
                            event?.delimitedDate?.startDate
                                ? new Date(event.delimitedDate.startDate)
                                : training?.delimitedDate?.startDate
                                ? new Date(training.delimitedDate.startDate)
                                : null
                        }
                    />
                    <BoatOnDateSelector
                        disabled={
                            event?.delimitedDate === null &&
                            training?.delimitedDate === null
                        }
                        label={this.displayText('expirationDate')}
                        onChange={value => {
                            if (event?.eventType?.id === 22)
                                this.onChangeTraining('delimitedDate', {
                                    ...training?.delimitedDate,
                                    endDate: value,
                                })
                            else
                                updateStateEvent(this, {
                                    delimitedDate: {
                                        endDate: value,
                                    },
                                })
                        }}
                        value={
                            event?.delimitedDate?.endDate
                                ? new Date(event.delimitedDate.endDate)
                                : training?.delimitedDate?.endDate
                                ? new Date(training.delimitedDate.endDate)
                                : null
                        }
                    />
                </div>
                <div
                    className={`${classes.twoTextField} ${classes.verticalMobile}`}
                >
                    {(!event?.eventType?.id ||
                        (eventType &&
                            (eventType.spending || eventType.earning))) &&
                        this._renderAmount()}
                </div>
                <TextField
                    id="details"
                    variant="outlined"
                    label={this.displayText('details')}
                    margin="dense"
                    multiline
                    rows={3}
                    value={
                        event?.description
                            ? event.description
                            : training?.comment
                            ? training.comment
                            : ''
                    }
                    onChange={e => {
                        if (event?.eventType?.id === 22)
                            this.onChangeTraining('comment', e.target.value)
                        else
                            updateStateEvent(this, {
                                description: e.target.value,
                            })
                    }}
                    className={classes.textField}
                    InputProps={{
                        classes: {
                            input: classes.input,
                            marginDense: classes.marginInput,
                        },
                    }}
                    InputLabelProps={{
                        classes: {
                            root: classes.labelInput,
                        },
                    }}
                />
                <Typography className={classes.textAdd}>
                    {this.displayText('addDocument')}
                </Typography>
                <div
                    className={
                        !(
                            (this.state.fileNoUrl.event.length > 0 ||
                                event?.files?.length > 0) &&
                            event?.files?.length +
                                this.state.fileNoUrl.event.length >=
                                0
                        ) && error
                            ? classes.addDocError
                            : classes.addDoc
                    }
                    onClick={this.clickDocument}
                >
                    <AddAPhotoIcon
                        className={
                            !(
                                (this.state.fileNoUrl.event.length > 0 ||
                                    event?.files?.length > 0) &&
                                event?.files?.length +
                                    this.state.fileNoUrl.event.length >=
                                    0
                            ) && error
                                ? classes.docError
                                : classes.doc
                        }
                    />
                </div>
                {!(
                    (this.state.fileNoUrl.event.length > 0 ||
                        event?.files?.length > 0) &&
                    event?.files?.length + this.state.fileNoUrl.event.length >=
                        0
                ) &&
                    error && (
                        <Typography className={classes.errorDocument}>
                            {this.displayText('errorDocument')}
                        </Typography>
                    )}
                <input
                    onChange={e =>
                        this.addDocument(
                            e,
                            event?.eventType?.id !== 22 ? 'event' : 'training',
                        )
                    }
                    type="file"
                    id="file"
                    ref="fileUploader"
                    style={{ display: 'none' }}
                    multiple
                    required
                />
                <DisplayerImgModal
                    file={getImageToDisplay(
                        event?.eventType?.id !== 22 ? event : training,
                        event?.eventType?.id !== 22
                            ? this.state.displayNewFiles.event
                            : this.state.displayNewFiles.training,
                    )}
                    deleteDocumentNoUp={this.deleteDocumentNoUp}
                    loadingImage={this.state.loadingImage}
                />
            </>
        )
    }

    async save() {
        const {
            deletedFiles,
            fileNoUrl,
            event,
            training,
            isConfirmed,
        } = this.state
        const {
            delimitedDate,
            title,
            description,
            transaction,
            eventType,
        } = event
        const { dispatch, boat, groupId } = this.props
        if (
            training &&
            training.delimitedDate?.startDate &&
            (training.trainingType || training.userTraining) &&
            (training?.user || training?.userSubscribe) &&
            (fileNoUrl.training?.length > 0 || training?.files?.length > 0) &&
            (training?.files?.length ?? 0) +
                (fileNoUrl.training?.length ?? 0) >=
                0
        ) {
            if (!training.id) {
                const newTraining = {
                    ...training,
                    userGroup: {
                        id: groupId,
                    },
                    files: training.files || [],
                    transaction:
                        training.transaction?.amountEt ||
                        training.transaction?.amountIt
                            ? {
                                  amountEt: `${training.transaction?.amountEt}`,
                                  amountIt: `${training.transaction?.amountIt}`,
                              }
                            : null,
                }
                if (training.user?.userSubscribe !== null) {
                    newTraining.userSubscribe = training.user.userSubscribe
                    newTraining.user = null
                } else {
                    newTraining.user = training.user.user ? training.user.user : training.user
                    newTraining.userSubscribe = null
                }

                dispatch(
                    trainingActions.postTraining(newTraining, {
                        newFiles: fileNoUrl.training,
                        files: training.files || [],
                        deletedFiles: deletedFiles.training,
                    }),
                )
            } else {
                const updatedTraining = {
                    ...training,
                    transaction:
                        training.transaction?.amountEt ||
                        training.transaction?.amountIt
                            ? {
                                  amountEt: `${training.transaction?.amountEt}`,
                                  amountIt: `${training.transaction?.amountIt}`,
                              }
                            : null,
                    userGroup: {
                        id: groupId,
                    },
                    files: training.files || [],
                }

                if (training.user?.userSubscribe !== null) {
                    updatedTraining.userSubscribe = training.user.userSubscribe
                    updatedTraining.user = null
                }

                dispatch(
                    trainingActions.updateTraining(updatedTraining, {
                        newFiles: fileNoUrl.training,
                        files: training.files || [],
                        deletedFiles: deletedFiles.training,
                    }),
                )
            }
            return
        }

        if (
            (fileNoUrl.event?.length > 0 || event?.files?.length > 0) &&
            event?.files?.length + fileNoUrl.event?.length >= 0 &&
            title &&
            title !== '' &&
            eventType?.id &&
            delimitedDate?.startDate &&
            ((event.transaction === null
                ? true
                : parseFloat(event.transaction.amountIt) !== 0) ||
                isConfirmed)
        ) {
            if (event?.id) {
                dispatch(
                    putBOBEvents(event.id, title, {
                        newFiles: fileNoUrl.event,
                        files: event.files,
                        delimitedDate: delimitedDate,
                        transaction: transaction,
                        description: description,
                        eventTypeId: eventType.id,
                        deletedFiles: deletedFiles.event,
                    }),
                )
            } else {
                dispatch(
                    postBOBEvents(boat.id, eventType.id, title, {
                        files: fileNoUrl.event,
                        duplicateFiles: event.files,
                        delimitedDate: delimitedDate,
                        transaction: transaction,
                        description: description,
                    }),
                )
            }
            this.props.handleClose()
        } else if (
            (fileNoUrl.event.length > 0 || event?.files?.length > 0) &&
            event?.files?.length + fileNoUrl.event?.length >= 0 &&
            title &&
            title !== '' &&
            eventType?.id &&
            delimitedDate?.startDate &&
            (parseFloat(event.transaction.amountIt) === 0 || !isConfirmed)
        ) {
            // show ModalAmountItCheck
            this.setState({ checkModalOpen: 'inventoryCheck' })
        } else return this.setState({ error: true })
    }

    render() {
        const {
            title,
            event,
            training,
            activity,
            noCross = true,
            showFollowButton,
        } = this.props
        const { checkModalOpen, tabIndex } = this.state

        return (
            <>
                {this._renderTitle(
                    title,
                    noCross,
                    [
                        this.displayText('essentialInfos'),
                        event?.id ? this.displayText('activity') : undefined,
                    ],
                    undefined,
                    showFollowButton,
                )}
                {this._renderBody({
                    bodies: [
                        this._body(),
                        event?.id
                            ? this._renderActivity(activity, event, 'document')
                            : undefined,
                    ],
                })}
                {/* Si on est en mode édition et qu'on est sur le fil d'activité, alors pas de boutons */}
                {this._renderActions(
                    (event?.id || training?.id) && tabIndex === 1
                        ? []
                        : [
                              {
                                  label: this.displayText('validate'),
                                  action: this.save,
                              },
                          ],
                )}
                <BoatOnModal
                    openCondition={checkModalOpen}
                    handleClose={this.handleCloseCheckModal}
                    maxWidth={{ inventoryCheck: `sm` }}
                    modalCores={{
                        inventoryCheck: (
                            <ModalAmountItCheck
                                isConfirmedToggler={this.isConfirmedToggler}
                                save={this.save}
                                handleCloseCheckModal={
                                    this.handleCloseCheckModal
                                }
                            />
                        ),
                    }}
                    titles={{
                        inventoryCheck: `Confirmer`,
                    }}
                />
            </>
        )
    }
}

function mapStateToProps(state) {
    const user = (state.group?.groupsMembers?.linkRGU || []).find(
        userLinked =>
            userLinked.user?.email === state.authentication?.user?.email,
    )

    return {
        boat: state.bob.boat,
        currentUser: user,
        groupId: state.group?.currentGroupId,
        subscriptions: state.authentication.subscriptions,
        activity: state.activity.activity,
        trainingTypes: state.types.trainingTypes,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(DocumentModal))
